/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Fintool.WebApi
 * OpenAPI spec version: 1.0
 */
import {
  useQuery
} from '@tanstack/react-query'
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import axios from 'axios'
import type {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse
} from 'axios'
import type {
  GetExchangeItemGetExchangeItemDetailsParams
} from '../../model'



export const getExchangeItemGetIndicators = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.get(
      `https://api.fintool.org/ExchangeItem/getIndicators`,options
    );
  }


export const getGetExchangeItemGetIndicatorsQueryKey = () => {
    return [`https://api.fintool.org/ExchangeItem/getIndicators`] as const;
    }

    
export const getGetExchangeItemGetIndicatorsQueryOptions = <TData = Awaited<ReturnType<typeof getExchangeItemGetIndicators>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getExchangeItemGetIndicators>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetExchangeItemGetIndicatorsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getExchangeItemGetIndicators>>> = ({ signal }) => getExchangeItemGetIndicators({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getExchangeItemGetIndicators>>, TError, TData> & { queryKey: QueryKey }
}

export type GetExchangeItemGetIndicatorsQueryResult = NonNullable<Awaited<ReturnType<typeof getExchangeItemGetIndicators>>>
export type GetExchangeItemGetIndicatorsQueryError = AxiosError<unknown>

export const useGetExchangeItemGetIndicators = <TData = Awaited<ReturnType<typeof getExchangeItemGetIndicators>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getExchangeItemGetIndicators>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetExchangeItemGetIndicatorsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getExchangeItemGetExchangeItems = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.get(
      `https://api.fintool.org/ExchangeItem/getExchangeItems`,options
    );
  }


export const getGetExchangeItemGetExchangeItemsQueryKey = () => {
    return [`https://api.fintool.org/ExchangeItem/getExchangeItems`] as const;
    }

    
export const getGetExchangeItemGetExchangeItemsQueryOptions = <TData = Awaited<ReturnType<typeof getExchangeItemGetExchangeItems>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getExchangeItemGetExchangeItems>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetExchangeItemGetExchangeItemsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getExchangeItemGetExchangeItems>>> = ({ signal }) => getExchangeItemGetExchangeItems({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getExchangeItemGetExchangeItems>>, TError, TData> & { queryKey: QueryKey }
}

export type GetExchangeItemGetExchangeItemsQueryResult = NonNullable<Awaited<ReturnType<typeof getExchangeItemGetExchangeItems>>>
export type GetExchangeItemGetExchangeItemsQueryError = AxiosError<unknown>

export const useGetExchangeItemGetExchangeItems = <TData = Awaited<ReturnType<typeof getExchangeItemGetExchangeItems>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getExchangeItemGetExchangeItems>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetExchangeItemGetExchangeItemsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getExchangeItemGetExchangeItemDetails = (
    params?: GetExchangeItemGetExchangeItemDetailsParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.get(
      `https://api.fintool.org/ExchangeItem/getExchangeItemDetails`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetExchangeItemGetExchangeItemDetailsQueryKey = (params?: GetExchangeItemGetExchangeItemDetailsParams,) => {
    return [`https://api.fintool.org/ExchangeItem/getExchangeItemDetails`, ...(params ? [params]: [])] as const;
    }

    
export const getGetExchangeItemGetExchangeItemDetailsQueryOptions = <TData = Awaited<ReturnType<typeof getExchangeItemGetExchangeItemDetails>>, TError = AxiosError<unknown>>(params?: GetExchangeItemGetExchangeItemDetailsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getExchangeItemGetExchangeItemDetails>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetExchangeItemGetExchangeItemDetailsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getExchangeItemGetExchangeItemDetails>>> = ({ signal }) => getExchangeItemGetExchangeItemDetails(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getExchangeItemGetExchangeItemDetails>>, TError, TData> & { queryKey: QueryKey }
}

export type GetExchangeItemGetExchangeItemDetailsQueryResult = NonNullable<Awaited<ReturnType<typeof getExchangeItemGetExchangeItemDetails>>>
export type GetExchangeItemGetExchangeItemDetailsQueryError = AxiosError<unknown>

export const useGetExchangeItemGetExchangeItemDetails = <TData = Awaited<ReturnType<typeof getExchangeItemGetExchangeItemDetails>>, TError = AxiosError<unknown>>(
 params?: GetExchangeItemGetExchangeItemDetailsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getExchangeItemGetExchangeItemDetails>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetExchangeItemGetExchangeItemDetailsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



