import React, { useEffect } from 'react';
import { Card, CardContent, Typography, Button, Box } from '@mui/material';
import { useUserContext } from '../../components/Shared/UserContext'; 
import { useNavigate } from 'react-router-dom';
import './Profile.css';
import { logout } from '@services/auth.service';
import { AxiosResponse } from 'axios';
import { AccountDetails } from '../../client/account/AccountDetails';
import { GetAccountDetailsRequest } from '../../model/getAccountDetailsRequest';
import { useGetAccountGetAccountDetails } from '../../client/account';
import qs from 'qs';
import { GetAccountGetAccountDetailsParams } from '../../model';

const Profile = () => {
  const customParamsSerializer = (params: any) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
  };
  const username = localStorage.getItem("username")?.replace(/"/g, '');
  const password = localStorage.getItem("password")?.replace(/"/g, '');
  const getItemDataRequest: GetAccountGetAccountDetailsParams = {
    userEmail: username,
    password: password
};

  const { data: profileData, error: profileError } = useGetAccountGetAccountDetails<AxiosResponse<AccountDetails>>(getItemDataRequest, {
    query: {
        retry: false,
    },
    axios: {
        paramsSerializer: customParamsSerializer
    }
});

  const { currentUser, setCurrentUserContext } = useUserContext();
  const navigate = useNavigate();

  // Funkcja wylogowania
  const handleLogout = () => {
    logout();
    setCurrentUserContext(null);
    navigate('/login'); 
  };

  useEffect(() => {
    console.log("Komponent Login zamontowany lub odświeżony");

    console.log(profileData);

  });

  return (
    <>
    <div 
      className="profile-page-wrapper"
    >
      <Card sx={{ minWidth: 300 }} className="profile-card">
        <CardContent>
          <Typography variant="h5" component="div">
            User Profile
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
            <strong>Email:</strong> {profileData?.data.email || "Unknown"}
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
            <strong>FirstName:</strong> {profileData?.data.firstName || "Unknown"}
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
            <strong>LastName:</strong> {profileData?.data.lastName || "Unknown"}
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
            <strong>isAdmin:</strong> {profileData?.data.isAdmin.toString()}
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
            <strong>isPremium:</strong> {profileData?.data.isPremium.toString()}
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
            <strong>isProfessional:</strong> {profileData?.data.isProfessional.toString()}
          </Typography>
        </CardContent>
        <Box display="flex" justifyContent="center" sx={{ mb: 2 }}>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleLogout}
          >
            Logout
          </Button>
        </Box>
      </Card>
    </div>
    </>
  );
};

export default Profile;
