import React from 'react';
import "./Home.css";
import { ErrorBoundary } from '@components/Shared';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Header } from '@components/Header'

const Home = () => {
    // const dispatch = useDispatch();
    // const { user } = useSelector((x: any) => x.auth);
    // const { users } = useSelector((x: any) => x.users);

    useEffect(() => {
        // dispatch(userActions.getAll());
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    

    return (
        <>
        <ErrorBoundary>
            <div className="home-page-wrapper">
                <h1>In progress</h1>
            </div>
            
            {/* <Link to='/login'>Login</Link>
            <Link to='/register'>Register</Link> */}
        </ErrorBoundary>
        </>
    );
};

export default Home;
