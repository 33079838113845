/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Fintool.WebApi
 * OpenAPI spec version: 1.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import axios from 'axios'
import type {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse
} from 'axios'
import type {
  CreateAccountRequest,
  GetAccountGetAccountDetailsParams,
  GetTokenRequest
} from '../../model'



export const postAccountCreate = (
    createAccountRequest: CreateAccountRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.post(
      `https://api.fintool.org/Account/create`,
      createAccountRequest,options
    );
  }



export const getPostAccountCreateMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAccountCreate>>, TError,{data: CreateAccountRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postAccountCreate>>, TError,{data: CreateAccountRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAccountCreate>>, {data: CreateAccountRequest}> = (props) => {
          const {data} = props ?? {};

          return  postAccountCreate(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostAccountCreateMutationResult = NonNullable<Awaited<ReturnType<typeof postAccountCreate>>>
    export type PostAccountCreateMutationBody = CreateAccountRequest
    export type PostAccountCreateMutationError = AxiosError<unknown>

    export const usePostAccountCreate = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAccountCreate>>, TError,{data: CreateAccountRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postAccountCreate>>,
        TError,
        {data: CreateAccountRequest},
        TContext
      > => {

      const mutationOptions = getPostAccountCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const postAccountToken = (
    getTokenRequest: GetTokenRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.post(
      `https://api.fintool.org/Account/token`,
      getTokenRequest,options
    );
  }



export const getPostAccountTokenMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAccountToken>>, TError,{data: GetTokenRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postAccountToken>>, TError,{data: GetTokenRequest}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAccountToken>>, {data: GetTokenRequest}> = (props) => {
          const {data} = props ?? {};

          return  postAccountToken(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostAccountTokenMutationResult = NonNullable<Awaited<ReturnType<typeof postAccountToken>>>
    export type PostAccountTokenMutationBody = GetTokenRequest
    export type PostAccountTokenMutationError = AxiosError<unknown>

    export const usePostAccountToken = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAccountToken>>, TError,{data: GetTokenRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postAccountToken>>,
        TError,
        {data: GetTokenRequest},
        TContext
      > => {

      const mutationOptions = getPostAccountTokenMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const getAccountGetAccountDetails = (
    params?: GetAccountGetAccountDetailsParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.get(
      `https://api.fintool.org/Account/getAccountDetails`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetAccountGetAccountDetailsQueryKey = (params?: GetAccountGetAccountDetailsParams,) => {
    return [`https://api.fintool.org/Account/getAccountDetails`, ...(params ? [params]: [])] as const;
    }

    
export const getGetAccountGetAccountDetailsQueryOptions = <TData = Awaited<ReturnType<typeof getAccountGetAccountDetails>>, TError = AxiosError<unknown>>(params?: GetAccountGetAccountDetailsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAccountGetAccountDetails>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAccountGetAccountDetailsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAccountGetAccountDetails>>> = ({ signal }) => getAccountGetAccountDetails(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAccountGetAccountDetails>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAccountGetAccountDetailsQueryResult = NonNullable<Awaited<ReturnType<typeof getAccountGetAccountDetails>>>
export type GetAccountGetAccountDetailsQueryError = AxiosError<unknown>

export const useGetAccountGetAccountDetails = <TData = Awaited<ReturnType<typeof getAccountGetAccountDetails>>, TError = AxiosError<unknown>>(
 params?: GetAccountGetAccountDetailsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAccountGetAccountDetails>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAccountGetAccountDetailsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



