/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Fintool.WebApi
 * OpenAPI spec version: 1.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import axios from 'axios'
import type {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse
} from 'axios'
import type {
  GetDataPredictionGetPredictionParams,
  GetDataPredictionGetPredictionStatusParams,
  PostDataPredictionCreatePredictionParams
} from '../../model'



export const postDataPredictionCreatePrediction = (
    params?: PostDataPredictionCreatePredictionParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.post(
      `https://api.fintool.org/DataPrediction/createPrediction`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getPostDataPredictionCreatePredictionMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postDataPredictionCreatePrediction>>, TError,{params?: PostDataPredictionCreatePredictionParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postDataPredictionCreatePrediction>>, TError,{params?: PostDataPredictionCreatePredictionParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postDataPredictionCreatePrediction>>, {params?: PostDataPredictionCreatePredictionParams}> = (props) => {
          const {params} = props ?? {};

          return  postDataPredictionCreatePrediction(params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostDataPredictionCreatePredictionMutationResult = NonNullable<Awaited<ReturnType<typeof postDataPredictionCreatePrediction>>>
    
    export type PostDataPredictionCreatePredictionMutationError = AxiosError<unknown>

    export const usePostDataPredictionCreatePrediction = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postDataPredictionCreatePrediction>>, TError,{params?: PostDataPredictionCreatePredictionParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postDataPredictionCreatePrediction>>,
        TError,
        {params?: PostDataPredictionCreatePredictionParams},
        TContext
      > => {

      const mutationOptions = getPostDataPredictionCreatePredictionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const getDataPredictionGetPrediction = (
    params?: GetDataPredictionGetPredictionParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.get(
      `https://api.fintool.org/DataPrediction/getPrediction`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetDataPredictionGetPredictionQueryKey = (params?: GetDataPredictionGetPredictionParams,) => {
    return [`https://api.fintool.org/DataPrediction/getPrediction`, ...(params ? [params]: [])] as const;
    }

    
export const getGetDataPredictionGetPredictionQueryOptions = <TData = Awaited<ReturnType<typeof getDataPredictionGetPrediction>>, TError = AxiosError<unknown>>(params?: GetDataPredictionGetPredictionParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDataPredictionGetPrediction>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDataPredictionGetPredictionQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDataPredictionGetPrediction>>> = ({ signal }) => getDataPredictionGetPrediction(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getDataPredictionGetPrediction>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDataPredictionGetPredictionQueryResult = NonNullable<Awaited<ReturnType<typeof getDataPredictionGetPrediction>>>
export type GetDataPredictionGetPredictionQueryError = AxiosError<unknown>

export const useGetDataPredictionGetPrediction = <TData = Awaited<ReturnType<typeof getDataPredictionGetPrediction>>, TError = AxiosError<unknown>>(
 params?: GetDataPredictionGetPredictionParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDataPredictionGetPrediction>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDataPredictionGetPredictionQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getDataPredictionGetPredictionStatus = (
    params?: GetDataPredictionGetPredictionStatusParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.get(
      `https://api.fintool.org/DataPrediction/getPredictionStatus`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetDataPredictionGetPredictionStatusQueryKey = (params?: GetDataPredictionGetPredictionStatusParams,) => {
    return [`https://api.fintool.org/DataPrediction/getPredictionStatus`, ...(params ? [params]: [])] as const;
    }

    
export const getGetDataPredictionGetPredictionStatusQueryOptions = <TData = Awaited<ReturnType<typeof getDataPredictionGetPredictionStatus>>, TError = AxiosError<unknown>>(params?: GetDataPredictionGetPredictionStatusParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDataPredictionGetPredictionStatus>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDataPredictionGetPredictionStatusQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDataPredictionGetPredictionStatus>>> = ({ signal }) => getDataPredictionGetPredictionStatus(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getDataPredictionGetPredictionStatus>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDataPredictionGetPredictionStatusQueryResult = NonNullable<Awaited<ReturnType<typeof getDataPredictionGetPredictionStatus>>>
export type GetDataPredictionGetPredictionStatusQueryError = AxiosError<unknown>

export const useGetDataPredictionGetPredictionStatus = <TData = Awaited<ReturnType<typeof getDataPredictionGetPredictionStatus>>, TError = AxiosError<unknown>>(
 params?: GetDataPredictionGetPredictionStatusParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDataPredictionGetPredictionStatus>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDataPredictionGetPredictionStatusQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



