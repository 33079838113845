import React, { useState } from "react";
import { TextField, Button, Container, Typography, Box } from "@mui/material";
import { useUserContext } from '../../components/Shared/UserContext'; 
import { getAccountGetAccountDetails, usePostAccountToken } from '../../client/account'; // Adjust the path as needed
import { GetAccountGetAccountDetailsParams, GetTokenRequest } from '../../model'; // Adjust the path as needed
import { useNavigate } from 'react-router-dom';
import "./Login.css"; // Import the CSS module
import { login } from "@services/auth.service";
import { AxiosResponse } from "axios";

const Login: React.FC = () => {
  const navigate = useNavigate();
  const { setCurrentUserContext } = useUserContext();
  const [signInData, setSignInData] = useState({
    username: '',
    password: '',
  });

  // Mutation hook for getting account token
  const getTokenMutation = usePostAccountToken({
    // Pass mutation options if needed
    // For example, you can pass onSuccess, onError callbacks here
  });

  // Function to handle sign-in form submission
  const handleSignIn = async (e: any) => {
    e.preventDefault(); // Prevent the default form submission behavior
    try {
      const getTokenRequest: GetTokenRequest = {
        userEmail: signInData.username,
        password: signInData.password,
      };

      const response = await getTokenMutation.mutateAsync({
        data: getTokenRequest,
      });
      console.log('Token obtained successfully:', response);

      const getAccountDetailsRequest: GetAccountGetAccountDetailsParams = {
        userEmail: signInData.username,
        password: signInData.password,
      };

      const accountDetailsResponse: AxiosResponse<any> = await getAccountGetAccountDetails(getAccountDetailsRequest);

      login(signInData.username, signInData.password, response.data, accountDetailsResponse.data.userId);
      setCurrentUserContext(response.data);
      setSignInData({ username: '', password: '' });
      navigate('/ai-prediction');
    } catch (error) {
      console.error('Error signing in:', error);
    }
  };


  
  const handleCreateAccount = async (e: any) => {
    e.preventDefault(); // Prevent the default form submission behavior
    navigate('/create-account')
  };


  // Function to handle form input changes
  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setSignInData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <div className="page">
      <Box className="container">
        <Typography variant="h4" component="h1" className="title">
          Sign In
        </Typography>
        <div>
          <TextField
            fullWidth
            margin="normal"
            variant="outlined"
            label="Username"
            type="text"
            name="username"
            value={signInData.username}
            onChange={handleInputChange}
            required
          />
          <TextField
            fullWidth
            margin="normal"
            variant="outlined"
            label="Password"
            type="password"
            name="password"
            value={signInData.password}
            onChange={handleInputChange}
            required
          />
          <div className="action-buttons">
            <Button
              type="button"
              variant="contained"
              color="primary"
              onClick={handleSignIn} // Handle the sign-in action here
            >
              Sign In
            </Button>
            <Button
              type="button"
              variant="contained"
              color="primary"
              onClick={handleCreateAccount} 
            >
              Create account
            </Button>
          </div>

        </div>
      </Box>
    </div>
  );
};

export default Login;
